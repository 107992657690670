<template>
<div class='uk-offcanvas-content'>
  <div class='StudentLayout'>

      <!-- header -->
      <message-component></message-component>
      <page-header/>
      <!-- content -->
      <main>
        <slot/>
      </main>

      <!-- //footer -->
      <page-footer/>
    </div>
  </div>
</template>

<script>
const PageHeader = () => import("../components/Studentcmp/StudentHeaderNew.vue");
const PageFooter = () => import("../components/Organizcmp/OrganizFooter.vue")
import MessageComponent from '@/components/globals/Notification.vue'
export default {
  name: 'StudentLayout',
  components: {
    MessageComponent,
    PageHeader,
    PageFooter
  },
  data () {
    return {

    }
  },
  created () {
    setTimeout(() => {

    }, 2000)
  }
}
</script>

<style scoped>
</style>
